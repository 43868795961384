/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                jQuery(function() {
                    jQuery('#menu-primary').slicknav({
                        label: '',
                        appendTo: 'header',
                    });
                });

        // jQuery( '#menu-service >.menu-item' ).mouseleave(function(){

        //         jQuery(this).find('.sub-menu').slideUp();

        // });

        jQuery( '#menu-service >.menu-item' ).mouseleave(function(){

            jQuery('#menu-service >.menu-item .sub-menu').slideUp();

        });


        jQuery( '#menu-service >.menu-item' ).mouseenter(function(){
            
            jQuery(this).find('.sub-menu').slideDown();

        });
      

        jQuery('.slider').slick({
                  
              infinite: true,
            //   autoplay: true,
            //   autoplaySpeed: 4000,
              slidesToShow: 1,
              slidesToScroll: 1,
            
              
                      
          });

           


            jQuery('.pre-slider').slick({

            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            fade: true,
            arrows: false,
            asNavFor: '.thumb-slider',
            adaptiveHeight: true
            
            });


            jQuery('.thumb-slider').slick({

            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows:true,
            asNavFor: '.pre-slider',
            focusOnSelect: true,
            nextArrow: '.button-next',


            responsive: [
    
                {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
                },
                {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
                }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
                    ]
                      
          });



          

          
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                jQuery('.home-slide').slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.